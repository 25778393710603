import Controller from '../controller/Controller';

export function instantiateController(
  controller,
  data,
  useCartOptions = false
) {
  if (!controller) {
    controller = new Controller();
  }
  controller.cartReqParams = data?.output?.cartReqParams;
  controller.cartOptions = null;
  if (useCartOptions) {
    controller.cartOptions = data?.output?.cartOptions;
  }
  return controller;
}
