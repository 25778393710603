import { CLOSE_DRAWER_ACTION, THD_DOMAIN_REGEX } from '../common/constants';
import CustomDrawer from './custom-drawer/CustomDrawer';
const { React, ReactDOM } = window;

export default class Drawer {
  constructor({ hidden = false, src }) {
    this._containerId = 'thd-drawer-container';
    this.close = this.close.bind(this);
    this.hidden = hidden;
    this.src = src;
  }

  _addCloseDrawerEventListener() {
    const eventListenerFunc = (event) => {
      if (!THD_DOMAIN_REGEX.test(event.origin)) {
        return;
      }
      if (event.data.action === CLOSE_DRAWER_ACTION) {
        this.close();
      }
    };
    window.addEventListener('message', eventListenerFunc, false);
  }

  close() {
    ReactDOM.render(<div></div>, document.getElementById(this._containerId));
    document.body.style.overflow = 'visible';
    this.isOpen = false;
  }

  open(message) {
    let drawerContainer = document.getElementById(this._containerId);
    if (!drawerContainer) {
      drawerContainer = document.createElement('div');
      drawerContainer.id = this._containerId;
    } 
    document.getElementsByTagName('body')[0].appendChild(drawerContainer);

    if (!this.hidden) {
      document.body.style.overflow = 'hidden';
    }
    ReactDOM.render(
      <CustomDrawer
        close={this.close}
        hidden={this.hidden}
        postMessage={this.postMessage.bind(this, message)}
        src={this.src}
      />,
      document.getElementById(this._containerId)
    );
    this.isOpen = true;
    this._addCloseDrawerEventListener();
  }

  postMessage(message) {
    if (!message) return;
    const trans = '*';
    const iframe = document.getElementById('thd-drawer-frame');
    iframe.contentWindow.postMessage(message, trans);
  }
}