import { EVENT_BUS } from './common/constants';
import { instantiateController } from './common/utils';

let controller;

if (EVENT_BUS) {
  EVENT_BUS.on('cart.add-to-cart', function (data) {
    controller = instantiateController(controller, data, true);
    controller
      .addToCart()
      .on('success', (resp) => {
        EVENT_BUS.trigger('add-to-cart.success', resp);
        if (data.output.onAddToCartClick) data.output.onAddToCartClick(resp);
        if (data.output.onSuccess) data.output.onSuccess(resp);
      })
      .on('error', (err) => {
        EVENT_BUS.trigger('add-to-cart.error', err);
        if (data.output.onAddToCartClick) data.output.onAddToCartClick(err);
        if (data.output.onFail) data.output.onFail(err);
      });
  });

  EVENT_BUS.on('cart.check-availability', function (data) {
    controller = instantiateController(controller, data);
    controller
      .applianceCheckAvailability()
      .on('success', (resp) => {
        EVENT_BUS.trigger('check-availability.success', resp);
        if (data.output.onSuccess) data.output.onSuccess(resp);
      })
      .on('error', (err) => {
        EVENT_BUS.trigger('check-availability.error', err);
        if (data.output.onFail) data.output.onFail(err);
      });
  });

  EVENT_BUS.on('cart.instant-checkout', function (data) {
    controller = instantiateController(controller, data);
    controller
      .instantCheckout()
      .on('success', (resp) => {
        EVENT_BUS.trigger('instant-checkout.success', resp);
        if (data.output.onAddToCartClick) data.output.onAddToCartClick(resp);
        if (data.output.onSuccess) data.output.onSuccess(resp);
      })
      .on('error', (err) => {
        EVENT_BUS.trigger('instant-checkout.error', err);
        if (data.output.onAddToCartClick) data.output.onAddToCartClick(err);
        if (data.output.onFail) data.output.onFail(err);
      });
  });

  EVENT_BUS.on('cart.partsAndServices', function (data) {
    controller = instantiateController(controller, data);
    controller
      .partsAndServices()
      .on('success', (resp) => {
        EVENT_BUS.trigger('partsAndServices.success', resp);
        if (data.output.onSuccess) data.output.onSuccess(resp);
      })
      .on('error', (err) => {
        EVENT_BUS.trigger('partsAndServices.error', err);
        if (data.output.onFail) data.output.onFail(err);
      });
  });

  EVENT_BUS.on('cart.addHDPP', function (data) {
    controller = instantiateController(controller, data);
    controller
      .protectionPlan()
      .on('success', (resp) => {
        EVENT_BUS.trigger('addHDPP.success', resp);
        if (data.output.onSuccess) data.output.onSuccess(resp);
      })
      .on('error', (err) => {
        EVENT_BUS.trigger('addHDPP.error', err);
        if (data.output.onFail) data.output.onFail(err);
      });
  });

  EVENT_BUS.on('cart.select-store', function (data) {
    controller = instantiateController(controller, data);
    controller
      .selectAStore()
      .on('success', (resp) => {
        EVENT_BUS.trigger('select-a-store.success', resp);
        if (data.output.onAddToCartClick) data.output.onAddToCartClick(resp);
        if (data.output.onSuccess) data.output.onSuccess(resp);
      })
      .on('error', (err) => {
        EVENT_BUS.trigger('select-a-store.error', err);
        if (data.output.onAddToCartClick) data.output.onAddToCartClick(err);
        if (data.output.onFail) data.output.onFail(err);
      });
  });
}
