export const EXCLUDED_URL_PARAMS = [
  'channel',
  'hidden',
  'host',
  'itemIds',
  'lineItemIds',
  'pageType',
  'paypal',
  'misship',
  'component',
  'anchorProductSku',
  'recommendationType',
  'sharedSection',
];

export const OPTIONAL_JOIN_KEYS = ['itemId', 'lineItemId'];

export const NESTED_URL_PARAMS = {
  warrantyItem: [
    { key: 'warrantyItemId', value: 'itemId' }
  ],
  configuration: [
    { key: 'bundleId', value: 'id' },
    { key: 'type' },
  ],
  cartAttr: [
    { key: 'projectId' }
  ]
};

export const DRAWERS = {
  ADD_TO_CART: { path: 'atc', hasUrlParams: false },
  CHECK_AVAILABILITY: {
    path: 'appliance-check-availability',
    hasUrlParams: true,
  },
  PARTS_AND_SERVICES: { path: 'parts-and-services', hasUrlParams: true },
  PROTECTION_PLAN: { path: 'hdpp', hasUrlParams: true },
  SELECT_A_STORE: { path: 'bss', hasUrlParams: true },
};

export const CLOSE_DRAWER_ACTION = 'close-drawer';
export const THD_DOMAIN_REGEX = /^http(s?):\/\/.*\.homedepot(dev)?.com(:?\d*)$/;

export const EVENT_BUS = window?.THD_EVENT_BUS || window?.LIFE_CYCLE_EVENT_BUS;
