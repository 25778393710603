const { React } = window;

const CustomDrawer = ({ close, hidden, postMessage, src }) => {
  const [iframeLoaded, setIframeLoaded] = React.useState(false);
  const isMobile = window?.__EXPERIENCE_CONTEXT__?.channel === 'mobile';

  const drawerStyles = {
    background: 'white',
    maxWidth: '500px',
    ...(isMobile && {
      borderTopLeftRadius: '0.8rem',
      borderTopRightRadius: '0.8rem',
      position: 'absolute',
      bottom: 0,
      height: '88%',
    }),
  };

  const iframeStyles = isMobile
    ? { borderTopLeftRadius: '0.8rem', borderTopRightRadius: '0.8rem' }
    : {};

  const loaderStyles = {
    border: '4px solid #f96302',
    borderTop: '4px solid transparent',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    animation: 'spin 1s linear infinite',
  };

  const handleClick = (e) => {
    if (e.target.id !== 'thd-drawer-frame') close();
  };

  React.useEffect(() => {
    if (!hidden) setIframeLoaded(false);
  }, [hidden]);

  React.useEffect(() => {
    const styles = document.createElement('style');
    styles.innerHTML = `
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `;
    document.head.appendChild(styles);
  }, []);

  return hidden ? (
    <iframe
      className="sui-invisible"
      data-testid="thd-drawer-frame"
      id="thd-drawer-frame"
      onLoad={postMessage}
      src={src}
    />
  ) : (
    <div
      className="sui-fixed sui-inset-0 sui-flex sui-z-max"
      data-testid="custom-drawer"
      onClick={handleClick}
    >
      <div className="sui-fixed sui-inset-0 sui-bg-inverse/50"></div>
      <div
        className="sui-relative sui-flex sui-flex-col sui-w-full sui-h-full sui-ml-auto"
        style={drawerStyles}
      >
        {!iframeLoaded && (
          <div className="sui-flex sui-justify-center sui-items-center sui-h-full">
            <div style={loaderStyles}></div>
          </div>
        )}
        <iframe
          className={`sui-h-full sui-transition-all sui-duration-300 sui-ease-in-out ${
            iframeLoaded ? '' : 'sui-invisible'
          }`}
          data-testid="thd-drawer-frame"
          id="thd-drawer-frame"
          onLoad={() => {
            postMessage();
            setIframeLoaded(true);
          }}
          src={src}
          style={iframeStyles}
        />
      </div>
    </div>
  );
};

export default CustomDrawer;
